import React from "react";
import { css } from "@emotion/react";
import color from "~styles/color";

export function FeatureCard(props: {
  title: string;
  description: string;
  gif?: string;
  video?: string;
  image?: string;
}) {
  return (
    <div
      css={css`
        height: 450px;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
        vertical-align: top;
        margin-top: 25px;
        text-align: center;
      `}
    >
      <div
        css={css`
          font-size: 26px;
          margin-bottom: 17px;
          margin-top: -5px;
        `}
      >
        {props.title}
      </div>
      {props.video ? (
        <div
          css={css`
            width: 270px;
            height: 270px;
            overflow: hidden;
          `}
        >
          <video
            // controls
            autoPlay={true}
            loop={true}
            muted={true}
            css={css`
              width: 100%;
            `}
          >
            <source src={props.video} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div
          css={css`
            width: 100%;
            padding-top: 100%;
            background-image: url(${props.image ? props.image : props.gif});
            background-size: cover;
          `}
        />
      )}
      <div
        css={css`
          width: 270px;
          margin-top: 15px;
          line-height: 18px;
          font-size: 12px;
          color: ${color.gray8};
          text-align: left;
        `}
      >
        {props.description}
      </div>
    </div>
  );
}
