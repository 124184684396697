import React from "react";
import { Link } from "gatsby";
import { ExtendedHeader, EnvironmentContext } from "~components/header";
import Footer from "~components/footer";
import { HomeTitle } from "~components/text-helpers";
import { FeatureCard } from "~components//cards";
import { css } from "@emotion/react";
import color from "~styles/color";
import SiteHelmet from "~components/site-helmet";
import ManipulathorLogo from "~icons/ManipulaTHOR_Light.svg";
import { Popover } from "antd";
import { GithubOutlined, BarChartOutlined } from "@ant-design/icons";
import SEMANTIC_SCHOLAR_ICON from "~icons/semantic-scholar-thin.svg";
import CodeIcon from "@material-ui/icons/Code";

import DOF_VIDEO from "~media/manipulathor/manipulathor_dof.mp4";
import REAL_ARM_DESIGN from "~media/manipulathor/real-arm-design.png";
import SENSORS_VIDEO from "~media/manipulathor/sensors.mp4";
import MANIP_VIDEO from "~media/manipulathor/manipulation.mp4";

export default function Home() {
  return (
    <>
      <SiteHelmet coverImage="https://ai2thor.allenai.org/media/manipulathor/manipulathor-meta.jpg">
        <title>ManipulaTHOR</title>
        <meta
          name="keywords"
          content="ManipulaTHOR, Arm, Manipulation, Robotics"
        />
        <meta
          name="description"
          content="ManipulaTHOR is an environment within the AI2-THOR framework that facilitates visual manipulation of objects using a robotic arm."
        />
      </SiteHelmet>
      <ExtendedHeader
        videoLink="https://player.vimeo.com/video/537091707"
        upperContent={
          <EnvironmentContext
            logo={ManipulathorLogo}
            logoHeight="65px"
            maxWidth="620px"
            description="
              ManipulaTHOR is an environment within the AI2-THOR framework that facilitates visual manipulation of objects using a robotic arm.
          "
          />
        }
        bottomContent={
          <div
            css={css`
              > a div {
                background-color: ${color.gray8 + "88"};
                color: ${color.gray4};
                border-radius: 5px;
                display: inline-block;
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 4px;
                padding-top: 1px;
                margin-bottom: 10px;
                margin-top: 10px;
                margin-left: 8px;
                margin-right: 8px;
                font-size: 18px;
                transition-duration: 0.3s;

                &:hover {
                  background-color: ${color.gray8 + "55"};
                  color: ${color.gray6};
                }
              }
            `}
          >
            <a href="//github.com/allenai/manipulathor/" target="_blank">
              <div>
                <Popover
                  content={
                    <div>
                      The AllenAct codebase to reproduce
                      <br /> the models presented in the paper.
                    </div>
                  }
                  title={
                    <div>
                      <GithubOutlined /> allenai/manipulathor
                    </div>
                  }
                >
                  <GithubOutlined /> Code
                </Popover>
              </div>
            </a>
            <a
              href="//github.com/allenai/manipulathor/tree/main/datasets"
              target="_blank"
            >
              <div>
                <Popover
                  content={
                    <div>
                      Arm Point Nav Dataset (APND) task
                      <br /> description and setup.
                    </div>
                  }
                  title={
                    <div>
                      <GithubOutlined /> allenai/manipulathor/datasets
                    </div>
                  }
                >
                  <BarChartOutlined /> Dataset
                </Popover>
              </div>
            </a>
            <a
              href="//api.semanticscholar.org/CorpusID:233346916"
              target="_blank"
            >
              <div>
                <Popover
                  content={
                    <div>
                      We present ManipulaTHOR, a framework
                      <br /> that facilitates visual manipulation of
                      <br /> objects using a robotic arm...
                    </div>
                  }
                  title={
                    <div>
                      <img
                        src={SEMANTIC_SCHOLAR_ICON}
                        css={css`
                          height: 15px;
                          margin-bottom: 4px;
                          filter: invert(1);
                        `}
                      />{" "}
                      Paper PDF
                    </div>
                  }
                >
                  <img
                    src={SEMANTIC_SCHOLAR_ICON}
                    css={css`
                      height: 15px;
                      margin-bottom: 4px;
                    `}
                  />{" "}
                  Paper
                </Popover>
              </div>
            </a>
            <Link to="/manipulathor/documentation">
              <div>
                <Popover
                  content={
                    <div>
                      AI2-THOR API Reference for the arm setup
                      <br /> and each of the arm actions.
                    </div>
                  }
                  title={
                    <div>
                      <CodeIcon
                        css={css`
                          margin-bottom: -7px;
                        `}
                      />{" "}
                      ManipulaTHOR API Reference
                    </div>
                  }
                >
                  <CodeIcon
                    css={css`
                      margin-bottom: -6px;
                    `}
                  />{" "}
                  API Reference
                </Popover>
              </div>
            </Link>
          </div>
        }
      />
      <div
        css={css`
          text-align: center;
          padding-bottom: 80px;
          padding-top: 30px;
        `}
      >
        <HomeTitle title="Key Features" />
        <FeatureCard
          title="Mobile Manipulation"
          description="ManipulaTHOR enables navigation and object manipulation simultaneously."
          video={MANIP_VIDEO}
        />
        <FeatureCard
          title="Sensor Suite"
          description="ManipulaTHOR provides a suite of visual and non-visual sensors, such as egocentric RGB-D images and touch sensors."
          video={SENSORS_VIDEO}
        />
        <FeatureCard
          title="Real Arm Design"
          description="The arm is designed based on the Kinova Gen3 Modular Robotic Arm specification, bringing the simulation a step closer to reality."
          image={REAL_ARM_DESIGN}
        />
        <FeatureCard
          title="6-DOF Manipulation"
          description="The framework enables 6-DOF manipulation of objects, enabling fine-grained translation and rotation of the objects."
          video={DOF_VIDEO}
        />
      </div>
      <Footer />
    </>
  );
}
